
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterTextarea from '@/components/HipsterTextarea.vue';
  import { Component, Prop } from 'vue-property-decorator';
  import { Bookmaker, BookmakerPromotion, SelectOption, Sport } from '@/types/entities';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { mapMutations } from 'vuex';
  import { SET_BOOKMAKER_PROMOTIONS_ITEM_VALUE } from '@/store/bookmakerPromotions';
  import Vue from 'vue';
  import { FETCH_SPORTS } from '@/store/sports';
  import { FETCH_BOOKMAKERS } from '@/store/bookmakers';

  @Component({
    components: { HipsterSelector, InspiniaIbox, HipsterTextarea },
    mixins: [validationMixin],
    validations() {
      return {
        bookmakerPromotion: {
          bookmaker_id: { required },
          sport_id: { required },
          offer_description: { required }
        }
      };
    },
    methods: {
      ...mapMutations({
        setBookmakerPromotionValue: SET_BOOKMAKER_PROMOTIONS_ITEM_VALUE
      })
    }
  })
  export default class BookmakerPromotionEdit extends Vue {
    @Prop() bookmakerPromotion: BookmakerPromotion;

    public created(): void {
      this.$store.dispatch(FETCH_SPORTS, { is_supported: 1, all: 1 });
      this.$store.dispatch(FETCH_BOOKMAKERS, { is_public: 1, all: 1 });
    }

    public get bookmakerOptions(): Array<SelectOption<number>> {
      return this.$store.state.bookmakers.bookmakersList.data.map((bookmaker: Bookmaker) => ({
        name: bookmaker.name,
        value: bookmaker.id
      }));
    }

    public get sportOptions(): Array<SelectOption<number>> {
      return this.$store.state.sports.sportsList.data.map((sport: Sport) => ({
        name: sport.name,
        value: sport.id
      }));
    }
  }
